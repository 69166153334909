import {AppRegistrationOutlined, ExitToAppOutlined, InputOutlined, LoginOutlined, NoAccountsOutlined, RedoOutlined} from '@mui/icons-material';
import {Divider, Stack} from '@mui/material';
import Grid from '@mui/material/Grid2';
import getConfig from 'next/config';
import {FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {isLogged, redirect} from '../../../auth/service/LoginService';
import {useCommonAction} from '../../../common/action/CommonAction';
import {ActionCustomButton} from '../../../common/components/buttons/ActionCustomButton';
import {ContentBlock} from '../../../common/components/layout/ContentBlock';
import {PaperBlock} from '../../../common/components/layout/PaperBlock';
import {useAddNotification} from '../../../common/components/notifications/actions/NotificationsActions';
import {PageRoute} from '../../../common/constants';
import {isNotNullOrUndefinedOrEmpty, useMatches} from '../../../common/utils/CommonUtils';
import {Store} from '../../../lib/StoreType';
import {apolloClient} from '../../../lib/apolloClient';
import {NotificationType} from '../../../models/notification/NotificationModel';

const publicRuntimeConfig = getConfig().publicRuntimeConfig;

// Komponenta pro zobrazení vstupní obrazovky zadání emailu,eanu a obce v případě změny
export const StepOne: FC = () => {
    // redux state
    const loginSuccess = useSelector((state: Store) => state.common.loginSuccess);

    // 3rd methods
    const matches = useMatches();
    const {setActiveStep, setSuccessStep, logout} = useCommonAction();
    const {addNotification} = useAddNotification();

    // local variables
    const isLoggedUser = isLogged() && loginSuccess;

    // local methods
    const handleLogin = () => {
        if (isNotNullOrUndefinedOrEmpty(publicRuntimeConfig.FRONTEND_IDM_ENDPOINT)) {
            const uri = `${publicRuntimeConfig.FRONTEND_IDM_ENDPOINT}/?redirect_uri=${publicRuntimeConfig.VYJADROVACI_LINKA_REDIRECT_URI}&client_id=${publicRuntimeConfig.VYJADROVACI_LINKA_CLIENT_ID}&scope=vyjadrovacky_web&response_type=code&app=vyjadrovacky`;
            redirect(uri);
        }
    };
    const handleRegister = () => {
        if (isNotNullOrUndefinedOrEmpty(publicRuntimeConfig.FRONTEND_SELFCARE_ENDPOINT)) {
            const uri = `${publicRuntimeConfig.FRONTEND_SELFCARE_ENDPOINT}/login?zadatel=true`;
            redirect(uri);
        }
    };

    const handleForward = () => {
        redirect({pathname: PageRoute.OSOBNI_UDAJE}).then(() => {
            setActiveStep(1);
            setSuccessStep(0);
        });
    };

    const handleOnClickLogout = () => {
        addNotification({type: NotificationType.WARNING, text: <FormattedMessage id="common.logout" />});
        logout(apolloClient);
        setActiveStep(0);
    };

    return (
        <PaperBlock titleBlock={<FormattedMessage id="step.one.title" />} titleBlockIcon={<InputOutlined color="primary" fontSize="large" />}>
            <ContentBlock titleBlock={'step.one.description'} subBlock>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, md: 5}}>
                        <Stack spacing={2} direction="column" alignItems={'center'}>
                            {!isLoggedUser && (
                                <>
                                    <ActionCustomButton
                                        type="tooltipButton"
                                        title={'button.registered'}
                                        icon={<LoginOutlined fontSize="large" />}
                                        onClick={handleLogin}
                                        tooltipText="step.one.title.registered.tooltip"
                                        showTooltip
                                    />
                                    <ActionCustomButton
                                        type="onlyButton"
                                        title={'button.no.register'}
                                        icon={<NoAccountsOutlined fontSize="large" />}
                                        onClick={handleForward}
                                    />
                                </>
                            )}

                            {isLoggedUser && (
                                <>
                                    <ActionCustomButton
                                        type="onlyButton"
                                        title={'button.logout'}
                                        icon={<ExitToAppOutlined fontSize="large" />}
                                        onClick={handleOnClickLogout}
                                        minWidth
                                    />
                                    <ActionCustomButton
                                        type="onlyButton"
                                        title={'button.continue'}
                                        icon={<RedoOutlined fontSize="large" />}
                                        onClick={handleForward}
                                        minWidth
                                    />
                                </>
                            )}
                        </Stack>
                    </Grid>
                    <Grid size={{xs: matches ? 12 : 1}} sx={(theme) => ({padding: matches ? theme.spacing(2, 0, 2, 0) : undefined, marginRight: 10})}>
                        <Divider orientation={matches ? 'horizontal' : 'vertical'} />
                    </Grid>
                    <Grid size={{xs: 12, md: 5}}>
                        <Stack spacing={2} direction="column" alignItems={'center'}>
                            <ActionCustomButton
                                type="tooltipButton"
                                title={'button.registration'}
                                icon={<AppRegistrationOutlined fontSize="large" />}
                                onClick={handleRegister}
                                tooltipText="step.one.title.registration.tooltip"
                                showTooltip
                                minWidth
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </ContentBlock>
        </PaperBlock>
    );
};
