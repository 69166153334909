import {VyjadrovackaInvestor, VyjadrovackaUzivatelskyProfilAdresa, VyjadrovackaZadatel} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';

export const Zahranici = {
    ANO: 'ANO',
    NE: 'NE',
} as const;

export type Zahranici = (typeof Zahranici)[keyof typeof Zahranici];

export type KomodityList = {
    ELEKTRINA?: boolean;
    PLYN?: boolean;
};

export type VyjadrovackaTechnickaSpecifikaceCustom = {
    digitalniDokumentace?: boolean;
    udajeOStavbe: string;
    poznamka?: string | null;
};

export type VyjadrovackaZadatelCustom = Omit<VyjadrovackaZadatel, 'adresa'> & {
    zahranici: Zahranici;
    adresa: VyjadrovackaUzivatelskyProfilAdresa | null | undefined;
};

export type VyjadrovackaInputCustomData = {
    id?: string;
    investor?: VyjadrovackaInvestor & {zahranici: Zahranici};
    technickaSpecifikace: VyjadrovackaTechnickaSpecifikaceCustom;
    tid?: string;
    zadatel?: VyjadrovackaZadatelCustom;
    zadatelJeInvestor?: boolean;
    saveOsobniUdaje?: boolean;
};
