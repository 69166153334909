import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {WhatshotOutlined} from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {redirect} from '../auth/service/LoginService';
import {useCommonAction} from '../common/action/CommonAction';
import {CustomButtonStyle} from '../common/components/buttons/CustomButtonStyle';
import {ElectricityIcon} from '../common/components/icons/ElectricityIcon';
import {EgdLogo} from '../common/components/layout/EgdLogo';
import {GasLogo} from '../common/components/layout/GasLogo';
import {PaperBlock} from '../common/components/layout/PaperBlock';
import {PageRoute} from '../common/constants';
import {Div} from '../common/styledComponents/Div';
import {getQuerySelector, useMatches} from '../common/utils/CommonUtils';
import {Store} from '../lib/StoreType';
import {theme} from '../lib/theme';
import {StepOne} from './stepOne/components/StepOne';

/**
 * Komponenta pro zobrazení úvodního rozcestníku
 */
export const Rozcestnik = () => {
    // methods
    const {setKomoditaAction, setActiveStep, setSuccessStep} = useCommonAction();
    const matches = useMatches();

    // redux state
    const komodita = useSelector((state: Store) => state.common.komodita);
    const formKind = useSelector((state: Store) => state.common.formKind);
    const komoditaVyber = useSelector((state: Store) => state.common.komoditaVyber);
    //local variables
    const width = matches ? 100 : 250;
    const height = matches ? 50 : 120;
    const odstavitElektrina = formKind?.VYJADROVACKA?.ELEKTRINA?.komodita === Komodita.ELEKTRINA && !formKind?.VYJADROVACKA?.ELEKTRINA?.aktivni;
    const odstavitPlyn = formKind?.VYJADROVACKA?.PLYN?.komodita === Komodita.PLYN && !formKind?.VYJADROVACKA?.PLYN?.aktivni;

    const handleChangeKomodita = (komodita: Komodita) => {
        setKomoditaAction(komodita);

        const link = getQuerySelector("link[rel~='icon']");
        const icon = komodita === Komodita.PLYN ? '/static/images/favicongas.png' : '/static/images/favicon.ico';
        if (link.length > 0) {
            link.forEach((l) => {
                if (l instanceof HTMLLinkElement) {
                    l.href = icon; // Zde můžeme bezpečně přiřadit novou hodnotu href
                }
            });
        }

        // nastaveni komodity do cookie
        document.cookie = `komodita=${komodita}; path=/`;
        if (komoditaVyber) {
            setActiveStep(1);
            setSuccessStep(0);
            redirect({pathname: PageRoute.OSOBNI_UDAJE});
        }
    };

    if (komodita === Komodita.ELEKTRINA) {
        return <StepOne />;
    }
    if (komodita === Komodita.PLYN) {
        return <StepOne />;
    }
    if (!komodita) {
        document.cookie = `komodita=; path=/`;
    }

    return (
        <PaperBlock
            titleBlock={<FormattedMessage id="formular.nove.pripojeni.bez.komodity" />}
            description={<FormattedMessage id="formular.nove.pripojeni.bez.komodity.description" />}
            centerTitle
        >
            <Grid container>
                <Grid size={{xs: 12}} sx={{display: 'flex', justifyContent: 'center'}}>
                    <CustomButtonStyle
                        value={Komodita.ELEKTRINA}
                        icon={
                            <Div>
                                <ElectricityIcon
                                    logoColor={odstavitElektrina ? theme.palette.grey[500] : undefined}
                                    width={width / 1.1}
                                    height={height / 1.1}
                                />
                                <EgdLogo width={width} height={height} pathFill={odstavitElektrina ? theme.palette.grey[500] : theme.palette.error.main} />
                            </Div>
                        }
                        changeLocalValue={handleChangeKomodita}
                        disabled={odstavitElektrina}
                        fitContent
                    />
                    <CustomButtonStyle
                        value={Komodita.PLYN}
                        icon={
                            <Div>
                                <WhatshotOutlined
                                    sx={{
                                        width: width / 1.1,
                                        height: height / 1.1,
                                        color: odstavitPlyn ? theme.palette.grey[500] : 'inherit',
                                    }}
                                />
                                <GasLogo width={width} height={height} pathFill={odstavitPlyn ? theme.palette.grey[500] : 'inherit'} />
                            </Div>
                        }
                        changeLocalValue={handleChangeKomodita}
                        disabled={odstavitPlyn}
                        fitContent
                    />
                </Grid>
            </Grid>
        </PaperBlock>
    );
};
