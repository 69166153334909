import {Komodita, VyjadrovaciLinkaOdstavka, VyjadrovaciLinkaOdstavkaFormularTyp} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {ReactNode} from 'react';

export type ValidationError = {
    code?: string;
    field?: string;
    index?: number;
    message: string | ReactNode;
    name?: string;
    relatedIndex?: number;
    type?: ValidationErrorType;
};

export enum ValidationErrorType {
    CRITICAL,
    WARNING,
    CUSTOM,
}

export type FormKind = {
    [key in VyjadrovaciLinkaOdstavkaFormularTyp]: {[key in Komodita]?: VyjadrovaciLinkaOdstavka};
};
