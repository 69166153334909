import {VyjadrovackaAdresa, VyjadrovackaUzivatelskyProfilAdresa} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {Komodita} from '@eon.cz/apollo13-graphql-web';
import {useMediaQuery} from '@mui/material';
import {Maybe} from 'graphql/jsutils/Maybe';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import omitBy from 'lodash/omitBy';
import {ReactNode} from 'react';
import sanitizeHtml from 'sanitize-html';
import {formMapPath, stat} from '../constants';
import {ValidationError} from './CommonTypes';

export const nullAsEmpty = (s: string | null | undefined | Maybe<string>): string => (typeof s === 'string' ? s : '');
export const isNullOrUndefined = <T extends string | ValidationError | null | ReactNode>(val: T) => val == null;

export const isNullOrUndefinedOrEmpty = <T extends string | ValidationError | null | ReactNode>(val: T) =>
    isNullOrUndefined(val) || val === '' || val === 'undefined';

export const isNotNullOrUndefinedOrEmpty = <T extends string | ValidationError | undefined>(val: T) => !isNullOrUndefinedOrEmpty(val);
/**
 * Parse string to number with three decimal places
 *
 * @param {(number | string)} value
 * @return {*}
 */
export const parseDecimal = (value: number | string | undefined | null): number => (typeof value === 'number' ? value : Number(value?.split(',').join('.')));

/**
 * It returns true if the two objects are equal, except for functions
 * @param {T} prevProps - The previous props that were passed to the component.
 * @param {T} nextProps - The next props that will be received by the component.
 * @returns A function that takes two arguments, prevProps and nextProps, and returns a boolean.
 */
export const areEqual = <T extends Record<string, unknown>>(prevProps: T, nextProps: T): boolean => {
    const [prev, next] = [prevProps, nextProps].map((props) => omitBy(props, isFunction));
    return isEqual(prev, next);
};

const pathElectricity = {
    '/': 'EG.D > Rozcestník',
    '/osobniUdaje': 'EG.D > Osobní údaje',
    '/technickaSpecifikace': 'EG.D > Technická specifikace',
    '/souhrnZadosti': 'EG.D > Souhrn žádosti',
    '/podekovani': 'EG.D > Poděkování',
    '/_error': 'EG.D > Nenalezeno',
} as {[key: string]: string};

const pathGas = {
    '/': 'Gas Distribution > Rozcestník',
    '/osobniUdaje': 'Gas Distribution > Osobní údaje',
    '/technickaSpecifikace': 'Gas Distribution > Technická specifikace',
    '/souhrnZadosti': 'Gas Distribution > Souhrn žádosti',
    '/podekovani': 'Gas Distribution > Poděkování',
    '/_error': 'Gas Distribution > Nenalezeno',
} as {[key: string]: string};

/**
 * Funkce pro formátování cesty pro AppBar
 * @param {string} pathname
 */
export const transformPath = (pathname: string, komodita: Komodita | null | undefined, odstavka: boolean | undefined) => {
    const path = komodita === Komodita.ELEKTRINA ? pathElectricity : pathGas;
    if (!odstavka) return '';
    return !komodita ? '' : path[pathname]?.replace(/([a-zA-Za-žA-Ž ]+)[.!?]?\s*$/, '<strong>$1</strong>');
};

export const useMatches = (maxWidth = '500px') => useMediaQuery(`(max-width:${maxWidth})`);
export const useTablet = () => {
    const minWidth600 = useMediaQuery('(min-width:600px)');
    const maxWidth1200 = useMatches('1200px');
    return maxWidth1200 && minWidth600;
};

/**
 * It takes a string of HTML and returns a string of HTML that's been sanitized
 * @param {string} html - The HTML string to sanitize.
 */
export const sanitizeHTML = (html: string | undefined) => sanitizeHtml(html ?? '');

/**
 * Get pathname (URI) for given route. Komodita can be omitted when it is not necessary.
 * The whole point of this function is to prepend komodity or not depending on
 * configuration. This is core of the function.
 *
 * @param {PageRouteModel} route - Route to get path
 * @param {string} postfix - Postfix to append after the route. MUST start with '/' if not empty!
 * @param {Komodita} komodita - Komodita when needed. If needed and not provided, exception is thrown.
 */
const getParsePathname = (route: string, postfix?: string) => {
    if (typeof postfix === 'string' && postfix.length > 0 && postfix[0] !== '/') {
        throw Error(`Postfix ${postfix} not starting with '/'!`);
    }

    // Just a simple path
    return `${route}${typeof postfix === 'string' ? postfix : ''}`;
};

/**
 * Get path name from route and postfix, and convert empty string to slash (= go to root) since empty
 * string does not work that way.
 * @param {PageRoute} route - PageRoute
 * @param {string} [postfix] - string = ''
 * @returns A function that takes in a route and a postfix and returns a pathname.
 */
export const getPathname = (route: string, postfix?: string) => {
    // Get path name
    const pathName = getParsePathname(route, postfix);

    // Convert empty string to slash (= go to root) since empty string does not work that way
    return pathName === '' ? '/' : pathName;
};

export const getSampleImportFile = () => getPathname('/downloads', '/Vzor.pdf');

/**
 * It takes a step and a direction, and returns a new path
 * @param {number} step - The current step of the form.
 * @param {'left' | 'right'} direction - 'left' | 'right'
 * @returns A function that takes in a step and direction and returns a new path.
 */
export const changePage = (step: number, direction: 'left' | 'right') => {
    const newStep = direction === 'left' ? step - 1 : step + 1;
    return formMapPath(newStep);
};

/**
 * Funkce pro formátování adresy
 * @param {(VyjadrovackaSopAdresa | VyjadrovackaAdresa | undefined)} adresa
 * @return {*}  {string}
 */
export const formatAddress = <T extends VyjadrovackaAdresa | VyjadrovackaUzivatelskyProfilAdresa | null | undefined>(adresa: T): string => {
    if (adresa) {
        const {ulice, cisloPopisne, cisloOrientacni, cisloEvidencni, mistniCast, psc} = adresa;
        const obec = 'obec' in adresa ? adresa.obec : undefined;
        const isStat = 'stat' in adresa && adresa.stat;
        const hasCP = cisloPopisne && cisloPopisne.length > 0;
        const hasCO = cisloOrientacni && cisloOrientacni.length > 0;
        const hasCE = cisloEvidencni && cisloEvidencni.length > 0;

        let res = '';

        if (nullAsEmpty(obec)) {
            res += `${obec}`;
        }

        if (nullAsEmpty(mistniCast)) {
            res += `, ${mistniCast}`;
        }

        if (nullAsEmpty(ulice)) {
            res += `, ${ulice}`;
        }

        if (hasCP && hasCO) {
            res += `, č.p.${cisloPopisne}/č.o.${cisloOrientacni}`;
        } else if (hasCO) {
            res += `, č.o.${cisloOrientacni}`;
        } else if (hasCP) {
            res += `, č.p.${cisloPopisne}`;
        }
        if (hasCE) {
            res += `, č.e.${cisloEvidencni}`;
        }

        res += `${res.length > 1 && !!psc ? ', ' : ''} ${nullAsEmpty(psc)}`;
        if (isStat && typeof adresa.stat === 'object') {
            res += `, ${nullAsEmpty(stat.filter(({klic}) => klic !== 'CZ').reduce((sum, acc) => (acc.klic === adresa?.stat ? acc.dlouheOznaceni : sum), ''))}`;
        }
        if (isStat && typeof adresa.stat === 'string') {
            res += `${(adresa.stat as string) === 'Česká republika' ? '' : ','}${nullAsEmpty(
                stat.filter(({klic}) => klic !== 'CZ').reduce((sum, acc) => (acc.klic === adresa?.stat ? acc.dlouheOznaceni : sum), ''),
            )}`;
        }

        // When we ended with just two spaces, address is empty
        return res === '  ' ? '-' : res;
    }
    return '';
};
/**
 * Checking if the input string date is valid or not
 * @param dateInput {string} ["29-02-2000"]
 */
export const validateDate = (dateInput = '') => {
    if (!dateInput) {
        // invalid date if dateInput is empty
        return true;
    }
    if (dateInput?.length < 10) {
        // invalid date if dateInput length is less than 10
        return true;
    }
    // splitter will split the date and get the date seperator eg: -
    const splitter = dateInput.replace(/[0-9]/g, '')[0] ?? '-';
    // using splitter will get the parts of the date
    const parts = dateInput.split(splitter);

    // since year can be in front for yyyy-mm-dd and in last for dd-mm-yyyy taking care of that logic
    const year = parts[0]?.length === 4 ? parts[0] : parts[2];
    // month will be always in center
    const month = parts[1] ?? '';
    // taking care of day for the different formats like yyyy-mm-dd or dd-mm-yyyy
    const day = parts[0]?.length === 4 ? parts[2] : parts[0];

    // creating date our of the year, month day
    const date = new Date(Number(year), +month - 1, Number(day));

    // isValid date is true if the date is valid else false
    return Boolean(+date) && date.getDate() === Number(day);
};

/**
 * Format street line from the address
 *
 * @param {VyjadrovackaAdresa} adresa VyjadrovackaAdresa
 */
export const formatStreetLine = (adresa: VyjadrovackaAdresa | undefined) => {
    let res = adresa?.ulice ?? '';

    if (adresa?.cisloOrientacni) {
        res +=
            typeof adresa?.cisloPopisne === 'string' && adresa?.cisloPopisne.length > 0
                ? ` č.p.${adresa?.cisloPopisne ?? ''}/č.o.${adresa?.cisloOrientacni ?? ''}`
                : ` č.o.${adresa?.cisloOrientacni ?? ''}`;
    } else if (typeof adresa?.cisloPopisne === 'string' && adresa?.cisloPopisne.length > 0) {
        res += ` č.p.${adresa?.cisloPopisne ?? ''}`;
    }

    if (adresa?.cisloEvidencni) {
        res += ` č.e.${adresa?.cisloEvidencni ?? ''}`;
    }

    return res;
};

export const getQuerySelector = (selector: string) => {
    return document.querySelectorAll(selector);
};

export const changeFavicon = (komodita: Komodita | undefined | null) => {
    const link = getQuerySelector("link[rel~='icon']");
    const icon = komodita === Komodita.PLYN ? '/static/images/favicongas.png' : '/static/images/favicon.ico';
    if (link.length > 0) {
        link.forEach((l) => {
            if (l instanceof HTMLLinkElement) {
                l.href = icon; // Zde můžeme bezpečně přiřadit novou hodnotu href
            }
        });
    }

    // nastaveni komodity do cookie
    document.cookie = `komodita=${komodita}; path=/`;
};
