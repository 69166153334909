import {ApolloProvider} from '@apollo/client';
import {EmotionCache} from '@emotion/react';
import {AppCacheProvider} from '@mui/material-nextjs/v14-pagesRouter';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import NextProgress from 'next-progress';
import App, {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import {useEffect, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {CustomFormats, RawIntlProvider, createIntl, createIntlCache} from 'react-intl';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import persistStore from 'redux-persist/lib/persistStore';
import {ErrorFallback} from '../client/common/components/error/ErrorFallback';
import {PageLayout} from '../client/common/components/layout/PageLayout';
import {browserSupoort} from '../client/common/constants';
import {useApollo} from '../client/lib/apolloClient';
import store from '../client/lib/store';
import {useTheme} from '../client/lib/theme';
import Czech from '../lang/cs.json';
import English from '../lang/en.json';
/* Root stylesheet */
import {Komodita} from '@eon.cz/apollo13-graphql-vyjadrovaci-linka';
import {redirect} from '../client/auth/service/LoginService';
import {DynamicFavicon} from '../client/common/components/helperComponents/DynamicFavicon';
import '../public/static/fonts/BrixSans/stylesheet.css';

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

type Props = AppProps & {
    readonly statusCode: number;
    readonly emotionCache?: EmotionCache;
    readonly locale: string;
    readonly formats: CustomFormats | undefined;
    readonly komodita: Komodita;
};
const persistor = persistStore(store);
const MyApp = (props: Props) => {
    const {Component, locale, formats, pageProps, statusCode, komodita} = props;

    const apolloClient = useApollo(store.dispatch);

    const {theme} = useTheme();

    const [shortLocale] = locale ? locale.split('-') : ['en'];

    const messages = useMemo(() => {
        switch (shortLocale as string) {
            case 'cs':
                return Czech;
                break;
            case 'en':
                return English;
                break;
            default:
                return Czech;
        }
    }, [shortLocale]);

    useEffect(() => {
        if (!browserSupoort(window.navigator.userAgent)) {
            redirect({pathname: '/warning.html'});
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <RawIntlProvider value={createIntl({locale, messages, formats}, intlCache)}>
                        <AppCacheProvider {...props}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <ErrorBoundary FallbackComponent={ErrorFallback}>
                                    <Head>
                                        <meta
                                            name="viewport"
                                            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                                        />
                                        {/* eslint-disable-next-line i18next/no-literal-string */}
                                        <title>Sdělení k existenci sítí</title>
                                    </Head>
                                <DynamicFavicon komodita={komodita} />
                                    <NextProgress color={theme.palette.primary.main} height={4} options={{showSpinner: false}} />
                                    <PageLayout statusCode={statusCode}>
                                        <Component {...pageProps} statusCode={statusCode} />
                                    </PageLayout>
                                </ErrorBoundary>
                            </ThemeProvider>
                        </AppCacheProvider>
                    </RawIntlProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    );
};

const getInitialProps = async (appContext: Partial<AppContext>) => {
    const {ctx} = appContext;

    const komodita =
        ctx?.req?.headers.cookie
            ?.split('; ')
            .find((row) => row.startsWith('komodita='))
            ?.split('=')[1] ?? '';

    const [aVyjadrovackarops] = await Promise.all([App.getInitialProps(appContext as AppContext)]);

    const statusCode = ctx?.res?.statusCode;

    const formats = getFormats();

    return {
        ...aVyjadrovackarops,
        statusCode,
        formats,
        locale: getLocales(ctx?.req?.headers['accept-language']),
        komodita,
    };
};

MyApp.getInitialProps = getInitialProps;

const getLocales = (str: string | undefined) => {
    return str?.split(',').map((type) => type.split(';')[0]?.trim().replace('*', ''))[0] ?? 'cs';
};

const getFormats = () => ({
    number: {
        CS: {
            style: 'currency',
            currency: 'CZK',
        },
        EN: {
            style: 'currency',
            currency: 'US',
        },
    },
});

export default MyApp;
